import './styles.scss';

import React from 'react';

import right from '../../assets/images/caseStudies/nextIndicator.png';

interface IProps {
    type: 'left' | 'right';
    onClick?: () => void;
}
export const Arrows: React.FC<IProps> = ({ type, onClick }) => {
    return <img src={right} onClick={onClick} className={`arrow ${type}`} />;
};
