import './App.scss';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Pages } from './pages';

export const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Pages />
            <ToastContainer />
        </BrowserRouter>
    );
};
