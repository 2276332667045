import './styles.scss';
import 'react-multi-carousel/lib/styles.css';

import React from 'react';
import Carousel from 'react-multi-carousel';

import nexdoimgthree from '../../../../assets/images/product/nexdodashboard.jpg';
import nexdoimgone from '../../../../assets/images/product/nexdopayment.jpg';
import nexdoimgtwo from '../../../../assets/images/product/nexdoservices.jpg';
import nexdoimgfour from '../../../../assets/images/product/nexdosuccess.jpg';
import { Arrows } from '../../../Arrows';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};
export const NexDoApplicationKnowMore: React.FC = () => {
    const img = [nexdoimgone, nexdoimgtwo, nexdoimgthree, nexdoimgfour];
    return (
        <div className='nexdoapplication-knowmore'>
            <Carousel
                responsive={responsive}
                swipeable={true}
                draggable={true}
                keyBoardControl={true}
                autoPlaySpeed={3000}
                removeArrowOnDeviceType='mobile'
                customLeftArrow={<Arrows type='left' />}
                customRightArrow={<Arrows type='right' />}
                containerClass='container-class'
                itemClass='item-class'
                className='carousel'
            >
                {img.map((items, index) => (
                    <div key={index}>
                        <img src={items} />
                    </div>
                ))}
            </Carousel>
        </div>
    );
};
