import './styles.scss';

import React from 'react';

import nodeIcon from '../../../../assets/images/gymapplicationDescription/nodeIcon.svg';
import reactIcon from '../../../../assets/images/gymapplicationDescription/reactIcon.svg';
import { useTheme } from '../../../../utils';

export const NexDoApplicationDescription: React.FC = () => {
    const { theme } = useTheme();

    const img = [reactIcon, nodeIcon];
    const content = [
        {
            img: true,
            heading: 'Technology',
            para: 'Embrackle redesigned their mobile app and web journeys, with specific attention to user engagement, we used React Native for the front-end and Node JS for the back-end to create a user-friendly and seamless experience for customers. The software was deployed in cloud through Azure. Additionally, the team transformed the existing technology stack and applications to ‘mobile native’ SaaS applications, improving the overall user experience. They implemented encoding/decoding of content for data privacy and governance of data security to protect sensitive information. The team also used MongoDB as the database, providing a scalable and reliable solution for data storage.',
        },
    ];
    return (
        <div className='nexdo-description'>
            {content.map((item, index) => (
                <>
                    <div className='heading' key={index}>
                        {item.heading}
                    </div>
                    <div className='para' key={index}>
                        {item.img && (
                            <div className='img' style={theme === 'dark' ? { filter: 'invert(1)' } : {}}>
                                {img.map((item, index) => (
                                    <img src={item} alt='' key={index} />
                                ))}
                            </div>
                        )}
                        <div className='descrip'>{item.para}</div>
                    </div>
                </>
            ))}
        </div>
    );
};
