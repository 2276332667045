import './styles.scss';

import React from 'react';

export const NexDoApplicationOverview: React.FC = () => {
    const overview = [
        {
            heading: 'Overview',
            para: 'eCommerce is a start-up home service e-commerce company based in New Zealand. Connecting customers to skilled professionals through mobile applications.',
        },
        {
            heading: 'Problem Faced',
            para: 'eCommerceidentified a gap in the market for a home service e-commerce platform that could connect customers to skilled professionals through mobile applications. However, their existing technology stack and applications were not optimized for a mobile-native experience, and they lacked analytics and data security measures to make informed decisions.',
        },
        {
            heading: 'Solution',
            para: "eCommerce was able to improve their mobile app and web journeys, resulting in a 29% increase in cart-to-sale conversion over three months. The team's implementation of encoding/decoding of content and data security measures helped to improve the merchant experience by providing portfolio and customer insight analytics. By using data analytics, eCommerce was able to simplify decision-making and improve overall efficiency.",
        },
    ];
    return (
        <div className='nexdoapplication-overview'>
            {overview.map((item, index) => (
                <div className='grid-wrapper' key={index}>
                    <div className='heading'>{item.heading}</div>
                    <p className='para'>{item.para}</p>
                </div>
            ))}
        </div>
    );
};
