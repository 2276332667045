[
  {
    "categoryName": "Software Development",
    "categoryDescription": "Open positions in our team",
    "jobs": [
      {
        "role": ".NET Developer",
        "sector": "Software",
        "experience": "4+",
        "city": "Coimbatore",
        "empType": "Full-time",
        "description": "We are looking for strong .Net Architects and .Net Developers to join our team. Ideal candidates should possess the following skills.",
        "responsibilities": [
          "4+ yrs experience developing web applications using ASP.NET, jQuery, and other JavaScript frameworks.",
          "4+ yrs experience in Web APIs and Architecture principles.",
          "4+ yrs development experience using MVC, .NET, .Net core, entity framework, Angular JS, OOPS, Web API, JSON, SQL Server/Oracle, AWS/ Azure.",
          "Data migration experience is preferred.",
          "Experience working in an agile environment.",
          "Strong communication skills.",
          "Solid understanding of application lifecycle and development methodologies.",
          "Understanding of general business practices; preference for candidates with experience in financial services or insurance.",
          "Experience in product development.",
          "Experience in containers and serverless environments."
        ],
        "qualifications": [],
        "Education": []
      },

      {
        "role": "Java Developer",
        "sector": "Software",
        "experience": "3+",
        "city": "Coimbatore",
        "empType": "Full-time",
        "description": "We are hiring Java Developers to join our team. You will be responsible for architecting, designing, and developing solutions using Java, Spring, Hibernate, and other open-source technologies.",
        "responsibilities": [
          " Translate application storyboards and use cases into functional applications",
          " Design, build, and maintain efficient, reusable, and reliable Java code",
          " Ensure the best possible performance, quality, and responsiveness of the applications",
          " Identify bottlenecks and bugs, and devise solutions to these problems",
          " Help maintain code quality, organization, and automatization."
        ],
        "qualifications": [
          "Proficient in Java, with a good knowledge of its ecosystems.",
          "Solid understanding of object-oriented programming.",
          "Familiar with various design and architectural patterns.",
          "Skill in writing reusable Java libraries.",
          "Familiarity with concepts of MVC, JDBC, and RESTful.",
          "Experience with popular web application frameworks, such as Play and Spark.",
          "Good working knowledge of frameworks including Spring, SpringMVC & SpringBoot.",
          "Experience with UI development using JavaScript, JSP, jQuery, ReactJS etc.",
          "Experience with ORM libraries like JPA2, Hibernate.",
          "Basic understanding of the class loading mechanism in Java.",
          "Creating database schemas that represent and support business processes.",
          "Basic understanding of JVM, its limitations, weaknesses, and workarounds.",
          "Implementing automated testing platforms and unit tests.",
          "Proficient understanding of code versioning tools, such as Git.",
          "Good knowledge of Microservices, AWS, and Kubernetes."
        ],
        "Education": []
      }
    ]
  }
]
