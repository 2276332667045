import React from 'react';

import {
    InsuranceAnalyticsCard,
    InsuranceAnalyticsDescription,
    InsuranceAnalyticsOverview,
} from '../components/ProductsDescription/Insuranceanalytics';

export const InsuranceAnalyticsPage: React.FC = () => {
    return (
        <>
            <InsuranceAnalyticsCard />
            <InsuranceAnalyticsOverview />
            {/* <InsuranceAnalyticsImg /> */}
            <InsuranceAnalyticsDescription />
            {/* <InsuranceAnalyticsKnowMore /> */}
        </>
    );
};
