import './styles.scss';

import React from 'react';
import { Link } from 'react-router-dom';

interface IProps {
    text?: string;
    clr?: string;
    link: string;
    external?: boolean;
}
export const BlueButton: React.FC<IProps> = ({ text, clr = 'blue', link, external }) => {
    return external ? (
        <a className={`${clr}-button`} href={link} target='_blank' rel='noreferrer'>
            <div>{text}</div>
        </a>
    ) : (
        <Link className={`${clr}-button`} to={link}>
            <div>{text}</div>
        </Link>
    );
};
