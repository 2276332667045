import './styles.scss';

import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';

import darkAnimi from '../../assets/lottieAnimation/achievements/darkAnimi.json';
import lightAnimi from '../../assets/lottieAnimation/achievements/lightAnimi.json';
import { useTheme } from '../../utils/ThemeProvider';

export const Achievement: React.FC = () => {
    const { theme } = useTheme();
    return (
        <div className='achievement full-vh' id='Achievement'>
            <div className='header'>In 2+ Years</div>

            <div className='grid-wrapper'>
                <div className='yrs'>Here is how we are synergizing</div>
                <div className='animi'>
                    {theme === 'light' ? <Player src={lightAnimi} autoplay loop /> : <Player src={darkAnimi} autoplay loop />}
                </div>
                <div className='achieved'>
                    <div>
                        <div>
                            <span>20+</span>
                            <div>Active Client Partners</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>15+</span>
                            <div>Projects Completed</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>8+</span>
                            <div>Consulting Domains</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>15+</span>
                            <div>Apps and Growing</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>12+</span>
                            <div>Technology Stacks</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>50+</span>
                            <div>Active Resources and Growing</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
