import './styles.scss';

import React from 'react';

import nodeIcon from '../../../../assets/images/gymapplicationDescription/nodeIcon.svg';
import reactIcon from '../../../../assets/images/gymapplicationDescription/reactIcon.svg';
import { useTheme } from '../../../../utils';

export const GymApplicationDescription: React.FC = () => {
    const { theme } = useTheme();

    const img = [reactIcon, nodeIcon];
    const content = [
        {
            img: true,
            heading: 'Technology',
            para: 'The Pounds App was built using Microsoft Azure, making it highly scalable and efficient for multi-tenant SAAS applications. The front-end was built using React, providing a fast and responsive user experience. The back-end was built with Dotnet, which provided a reliable and secure platform for the application. The use of PostgreSQL databases provided a robust and reliable solution for data storage and management. The SAAS model provided users with a cost-effective and flexible solution for their fitness tracking and management needs. The Pounds App offered a variety of features, including workout tracking, goal setting, and personalized training plans, making it a comprehensive solution for fitness enthusiasts and professionals alike.',
        },
    ];
    return (
        <div className='gym-description'>
            {content.map((item, index) => (
                <>
                    <div className='heading' key={index}>
                        {item.heading}
                    </div>
                    <div className='para' key={index}>
                        {item.img && (
                            <div className='img' style={theme === 'dark' ? { filter: 'invert(1)' } : {}}>
                                {img.map((item, index) => (
                                    <img src={item} alt='' key={index} />
                                ))}
                            </div>
                        )}
                        <div className='descrip'>{item.para}</div>
                    </div>
                </>
            ))}
        </div>
    );
};
