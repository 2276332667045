import './styles.scss';

import React from 'react';

export const InsuranceAnalyticsOverview: React.FC = () => {
    const overview = [
        {
            heading: 'Overview',
            para: 'Pounds is a multi tenant clown based platform that provides a highly scalable solution for users to track their fitness and provide workforce management for the employees.',
        },
        {
            heading: 'Problem Faced',
            para: 'Our primary challenge was to integrate the data from these two different sources into a unified data warehouse. The data sets were from different formats, sources, and databases, making it challenging to establish a clear data pipeline that can effectively process the data.',
        },
        {
            heading: 'Solution',
            para: 'Our data engineering and warehousing project delivered several valuable insights for our clients. For the insurance industry client, we were able to identify customer segments that are more likely to make a claim and provide personalized risk assessments for them. For the e-commerce industry client, we were able to identify customer trends and behavior that can inform marketing strategies and product development.',
        },
    ];
    return (
        <div className='insuranceapplication-overview'>
            {overview.map((item, index) => (
                <div className='grid-wrapper' key={index}>
                    <div className='heading'>{item.heading}</div>
                    <p className='para'>{item.para}</p>
                </div>
            ))}
        </div>
    );
};
