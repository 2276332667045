import './styles.scss';

import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';

import mainImg from '../../assets/lottieAnimation/landingCard/94317.json';

export const LandingCard: React.FC = () => {
    return (
        <div className='landing-card full-vh' id='Home'>
            <div className='description'>
                <div className='descrip-1'>
                    The Perfect <span>Partner</span> for all your Technology Needs.
                </div>
                <div className='descrip-2'>
                    We are an organization of extremely passionate professionals delivering world-class technology to our clients and
                    customers across the globe.
                </div>
                <a className='action-link' href='#Careers'>
                    Get connected
                </a>
            </div>
            <div className='description-animation'>
                <Player className='main-img' src={mainImg} autoplay loop />
            </div>
        </div>
    );
};
