import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import { ThemeProvider } from './utils/ThemeProvider';

const rootNode = document.getElementById('root');
ReactDOM.render(
    <ThemeProvider>
        <App />
    </ThemeProvider>,
    rootNode
);
