import './styles.scss';

import React from 'react';

import arrowIcon from '../../../../assets/images/jobDescriptionPage/careers_arrow.svg';
import { useTheme } from '../../../../utils';

export const InsuranceAnalyticsCard: React.FC = () => {
    const { theme } = useTheme();
    return (
        <div className='insuranceapplication-card vh' id='top'>
            <div className='arrow-icon' style={{ filter: theme === 'light' ? 'brightness(0)' : 'none' }}>
                <img src={arrowIcon} alt='back-button' onClick={() => history.back()} />
                <h3 onClick={() => history.back()}>Back to home</h3>
            </div>
            <div className='grid-wrapper'>
                <div className='descrip'>
                    <p>Data Solutions</p>
                    {/* <img src={gymimg} alt='' /> */}
                </div>
            </div>
        </div>
    );
};
