import './styles.scss';

import React from 'react';

export const PsgApplicationOverview: React.FC = () => {
    const overview = [
        {
            heading: 'Overview',
            para: 'Entelegen has created a user-friendly website and mobile application for identifying learning difficulties in young adults under 17 at an early stage. The condition we aim to identify causes difficulties in reading due to struggles with identifying speech sounds and learning how to relate to letters and words.',
        },
        {
            heading: 'Problem Faced',
            para: 'The traditional method of identifying these traits involved time-consuming and cumbersome manual screening tests and questionnaires.',
        },
        {
            heading: 'Solution',
            para: 'Entelegen has designed a website and mobile application that offer a comprehensive set of online screening tools aimed at identifying early signs of learning difficulties in individuals. These user-friendly tools are quick and efficient, and they can provide valuable insights into the presence of these tendencies. Our curated questionnaires cover various aspects such as word identification, speech identification, and picture identification and have been developed by leading researchers. They offer quick and easy-to-use solutions that can help determine if further testing or evaluation is required.',
        },
    ];
    return (
        <div className='psgapplication-overview'>
            {overview.map((item, index) => (
                <div className='grid-wrapper' key={index}>
                    <div className='heading'>{item.heading}</div>
                    <p className='para'>{item.para}</p>
                </div>
            ))}
        </div>
    );
};
