import React from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from '../Footer';
import { Navbar } from '../Navbar';

export const PageContainer: React.FC = () => {
    return (
        <>
            <Navbar />
            <div className='scroll-container'>
                <Outlet />
                <Footer />
            </div>
        </>
    );
};
