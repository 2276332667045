import './styles.scss';

import React from 'react';

import RectangleImg from '../../../../assets/images/gymapplicationImg/RectangleImg.svg';
import nexdodashboard from '../../../../assets/images/product/nexdodashboard.jpg';

export const NexDoApplicationImg: React.FC = () => {
    return (
        <div className='nexdoapplication-img'>
            <div className='grid-wrapper'>
                <img src={RectangleImg} style={{ transform: 'translateY(-60px)' }} alt='' />
                <img src={RectangleImg} style={{ transform: 'translateY(-40px)' }} alt='' />
                <img className='psg-image' src={nexdodashboard} alt='' />
                <img src={RectangleImg} style={{ transform: 'translateY(40px)' }} alt='' />
                <img src={RectangleImg} style={{ transform: 'translateY(60px)' }} alt='' />
            </div>
        </div>
    );
};
