import './styles.scss';

import React from 'react';

export const GymApplicationOverview: React.FC = () => {
    const overview = [
        {
            heading: 'Overview',
            para: 'Pounds is a multi tenant clown based platform that provides a highly scalable solution for users to track their fitness and provide workforce management for the employees.',
        },
        {
            heading: 'Problem Faced',
            para: 'Fitness enthusiasts and professionals required a fast and responsive fitness management solution that could easily scale and adapt to their changing needs. They also required a cost-effective solution that reduced the burden of maintenance and support.',
        },
        {
            heading: 'Solution',
            para: 'The Pounds App provided users with a fast, responsive, and highly scalable solution for their fitness tracking and management needs. The SAAS model allowed users to easily customize and scale their usage of the software based on their specific needs, without requiring additional hardware or software investments. The use of SQL and PostgreSQL databases ensured robust and reliable data storage and management.',
        },
    ];
    return (
        <div className='gymapplication-overview'>
            {overview.map((item, index) => (
                <div className='grid-wrapper' key={index}>
                    <div className='heading'>{item.heading}</div>
                    <p className='para'>{item.para}</p>
                </div>
            ))}
        </div>
    );
};
