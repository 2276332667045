import React from 'react';

import {
    GymApplicationCard,
    GymApplicationDescription,
    GymApplicationImg,
    GymApplicationKnowMore,
    GymApplicationOverview,
} from '../components/ProductsDescription/Gymapplication';

export const GymApplicationPage: React.FC = () => {
    return (
        <>
            <GymApplicationCard />
            <GymApplicationOverview />
            <GymApplicationImg />
            <GymApplicationDescription />
            <GymApplicationKnowMore />
        </>
    );
};
