import './styles.scss';

import React, { useLayoutEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import logoDark from '../../assets/images/navbar/logo-dark.png';
import logoLight from '../../assets/images/navbar/logo-light.png';
import { useTheme } from '../../utils';

export const Navbar: React.FC = () => {
    const { theme } = useTheme();
    const [toggleClick, setToggleClick] = useState(false);
    const [active, setActive] = useState(true);
    const [hide, setHide] = useState(false);
    const navigate = useNavigate();
    const linkItem = ['Home', 'Services', /*'Clients'*/, 'Products', 'Achievements', 'Careers'];
    const links = ['/#Home', '/#Services',/* '/#ClientCard'*/, '/#Products', '/#Achievement', '/#Career'];
    const hidden = {
        icon: () => {
            setActive(!active ? true : false);
            setHide(false);
        },
        link: () => {
            setToggleClick(!toggleClick);
            setHide(true);
            setActive(true);
        },
    };
    const { hash } = useLocation();

    useLayoutEffect(() => {
        setTimeout(() => {
            // hash.charAt(0) === '#' && console.log(document.querySelector(hash));
            hash.charAt(0) === '#' ? document.querySelector(hash)?.scrollIntoView({ behavior: 'smooth', block: 'start' }) : null;
        }, 0);
    }, [hash, toggleClick]);

    return (
        <nav>
            <div>
                {theme === 'light' ? (
                    <img
                        className='logo'
                        src={logoLight}
                        onClick={() => {
                            navigate('/#Home'), setToggleClick(!toggleClick);
                        }}
                    />
                ) : (
                    <img
                        className='logo'
                        src={logoDark}
                        onClick={() => {
                            navigate('/#Home'), setToggleClick(!toggleClick);
                        }}
                    />
                )}
                <button onClick={hidden.icon}>{active ? '\u2630' : '\u2A2F'}</button>
            </div>
            <div className={`${hide || active ? 'active' : null}`}>
                {linkItem.map((item, index) => (
                    <Link
                        to={links[index]}
                        key={index}
                        onClick={() => {
                            hidden.link(links[index]);
                        }}
                    >
                        {item}
                    </Link>
                ))}
                <Link className='action-link' to='/#Careers' onClick={() => hidden.link('/#Careers')}>
                    Contact
                </Link>
            </div>
        </nav>
    );
};
