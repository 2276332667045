import './styles.scss';

import React from 'react';

import RectangleImg from '../../../../assets/images/gymapplicationImg/RectangleImg.svg';
// import psgassessment from '../../assets/images/product/psgassessment.png';
import psgassessment from '../../../../assets/images/product/psgassessment.png';

export const PsgApplicationImg: React.FC = () => {
    return (
        <div className='psgapplication-img'>
            <div className='grid-wrapper'>
                <img src={RectangleImg} style={{ transform: 'translateY(-60px)' }} alt='' />
                <img src={RectangleImg} style={{ transform: 'translateY(-40px)' }} alt='' />
                <img className='psg-image' src={psgassessment} alt='' />
                <img src={RectangleImg} style={{ transform: 'translateY(40px)' }} alt='' />
                <img src={RectangleImg} style={{ transform: 'translateY(60px)' }} alt='' />
            </div>
        </div>
    );
};
