import './styles.scss';

import React from 'react';
import Carousel from 'react-multi-carousel';

import { BlueButton } from '../Button';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 2300 },
        items: 1,
    },
    largeDesktop: {
        breakpoint: { max: 2300, min: 1270 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 1270, min: 992 },
        items: 1,
    },
    smallDesktop: {
        breakpoint: { max: 992, min: 768 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 768, min: 680 },
        items: 1,
    },
    largeMobile: {
        breakpoint: { max: 680, min: 550 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 550, min: 400 },
        items: 1,
    },
    smallMobile: {
        breakpoint: { max: 400, min: 0 },
        items: 1,
    },
};

const product_details = [
    {
        title: 'Little champions',
        img: require('../../assets/images/product/dashboard_home.jpg'),
        p: 'Little champions is a powerful tool that utilizes advanced algorithms to identify early signs of learning difficulties. It is designed to be user-friendly and accessible, making it ideal for parents, educators, and healthcare professionals who are looking for a reliable solution to detect these tendencies through tests such as reading, writing, and spelling abilities and receive a comprehensive report on their performance.',
        link: '/product/psgapplication#top',
    },
    {
        title: 'eConnect WFM',
        img: require('../../assets/images/product/dashboard.png'),
        p: 'eConnect is a cloud-based, multi-tenant HRM tool that uses biometric authentication and cross-platform integration to accurately track employee productivity. Developed with React Native and Node JS, it provides a user-friendly interface and centralized data for businesses of various sizes and industries. The software eliminates time theft and buddy punching, streamlining workforce management processes and resulting in improved productivity and reduced costs. With its SAAS model, businesses can easily scale their usage of the software without requiring additional hardware or software resources.',
        link: '/product/econnectapplication#top',
    },
    {
        title: 'eCommerce - Home based services',
        img: require('../../assets/images/product/services.jpg'),
        p: 'eCommerce, a home service e-commerce startup in New Zealand, Entelegen redesigned their mobile app and web journeys using React Native and Node JS, creating a user-friendly and seamless experience for customers. We also transformed the existing technology stack to mobile-native SaaS applications, improving the overall user experience and implementing data privacy and governance of data security measures.',
        link: '/product/nexdoapplication#top',
        // fitimage: true,
    },
    {
        title: 'Pounds - (Multi-Tenant SAAS)',
        img: require('../../assets/images/product/gymapplication2.png'),
        p: 'Pounds is a highly scalable and efficient fitness management solution built on Microsoft Azure, with a fast and responsive front-end using React and a reliable, secure back-end using Dotnet. The SAAS model provides cost-effective and flexible usage for fitness enthusiasts and professionals, with features like workout tracking, goal setting, and personalized training plans. The app also offers robust and reliable data storage and management using PostgreSQL databases.',
        link: '/product/gymapplication#top',
    },
    {
        title: 'Data Solutions',
        img: require('../../assets/images/product/analytics.jpg'),
        p: 'Our company is a data engineering and warehousing firm that specializes in leveraging open source data sets to generate valuable insights and reports for clients. Recently, we worked on a project for a client in the insurance industry and another client in the e-commerce industry. The goal was to use their data to generate reports and studies that provide valuable insights on customer trends and performance.',
        link: '/product/insuranceanalytics#top',
    },
];

export const Product: React.FC = () => {
    return (
        <div className='product full-vh' id='Products'>
            <div className='header'>Some of Our Works</div>
            <div className='grid-wrapper'>
                <Carousel
                    responsive={responsive}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    keyBoardControl={true}
                    autoPlaySpeed={10000}
                    removeArrowOnDeviceType='mobile'
                    // customLeftArrow={<Arrows type='left' />}
                    // customRightArrow={<Arrows type='right' />}
                    arrows={false}
                    containerClass='container-class'
                    itemClass='item-class'
                    autoPlay={false}
                    // infinite={true}
                    customTransition='transform 500ms ease-in-out'
                    className='carousel'
                >
                    {product_details.map((items, index) => (
                        <div key={index} className='internal-wrapper'>
                            <div className='img'>
                                <img src={items.img} alt='' />
                            </div>
                            <div className='content' key={index}>
                                <div className='product-name'>{items.title}</div>
                                <div className='about'>
                                    About
                                    <p>{items.p}</p>
                                </div>
                                <BlueButton link={items.link} text='Know more' />
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};
