import './styles.scss';

import React from 'react';

import RectangleImg from '../../../../assets/images/gymapplicationImg/RectangleImg.svg';
import gymapplicationimg from '../../../../assets/images/product/gymapplication1.png';

export const GymApplicationImg: React.FC = () => {
    return (
        <div className='gymapplication-img'>
            <div className='grid-wrapper'>
                <img src={RectangleImg} style={{ transform: 'translateY(-60px)' }} alt='' />
                <img src={RectangleImg} style={{ transform: 'translateY(-40px)' }} alt='' />
                <img className='psg-image' src={gymapplicationimg} alt='' />
                <img src={RectangleImg} style={{ transform: 'translateY(40px)' }} alt='' />
                <img src={RectangleImg} style={{ transform: 'translateY(60px)' }} alt='' />
            </div>
        </div>
    );
};
