import './styles.scss';

import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import careerBanner from '../../assets/images/careerPage/careerBanner.svg';
import empTypeIcon from '../../assets/images/careerPage/empTypeIcon.svg';
import experienceIcon from '../../assets/images/careerPage/greyexperience.svg';
import locationIcon from '../../assets/images/careerPage/greylocation.svg';
import arrowIcon from '../../assets/images/jobDescriptionPage/careers_arrow.svg';
import jobData from '../../jobData.json';
import { useTheme } from '../../utils';

export const Career: React.FC = () => {
    const navigate = useNavigate();
    const { theme } = useTheme();
    const topRef = useRef<HTMLDivElement>({} as HTMLDivElement);
    useEffect(() => {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <div className='career full-vh' ref={topRef}>
            <div className='arrow-icon'>
                {theme === 'light' ? (
                    <img src={arrowIcon} alt='back-button' style={{ filter: 'brightness(0)' }} onClick={() => navigate('/')} />
                ) : (
                    <img src={arrowIcon} alt='back-button' onClick={() => navigate('/')} />
                )}
                <h3 onClick={() => navigate('/')}>Back to home</h3>
            </div>
            <div className='caption'>
                <h1>Start Doing What Matters</h1>
                <p>
                    Our philosophy is simple — hire a team of diverse, passionate people and foster a culture that empowers us to deliver
                    the best.
                </p>
            </div>
            {jobData.map((data, dataIndex) => (
                <div className='jobs-container' key={`job-data-${dataIndex}`}>
                    <div className='job-category'>
                        <h3 className='category-heading'>{data.categoryName}</h3>
                        <p>{data.categoryDescription}</p>
                    </div>
                    <div className='job-positions'>
                        {data.jobs.map((job, jobIndex) => (
                            <Link to={`/job-description/${dataIndex}/${jobIndex}`} key={`${job}+${jobIndex}`}>
                                <div className='position-card'>
                                    <div className='job-title-container'>
                                        <div className='job-role'>{job.role}</div>
                                        <div className='job-sector'>Job Description</div>
                                    </div>
                                    <p>{job.description}</p>
                                    <div className='employment-details'>
                                        <div className='employment-type'>
                                            <img src={empTypeIcon} alt='' />
                                            {job.empType}
                                        </div>
                                        <div className='experience'>
                                            <img src={experienceIcon} alt='' />
                                            {job.experience} years Experience
                                        </div>
                                        <div className='job-city'>
                                            <img src={locationIcon} alt='' />
                                            {job.city}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            ))}

            <div className='career-banner' style={{ backgroundImage: `url(${careerBanner})` }}>
                <h1>If there aren&apos;t opportunities, then make one!</h1>
                <p className='banner-para'>
                    At Entelegen, we&apos;re always on the lookout for niche talent. Click here to submit your resume and we&apos;ll get
                    back to you at the earliest.
                </p>
                <a href='mailto:careers@entelegen.com' target='_blank' rel='noreferrer'>
                    <button className='join-btn'>Join Entelegen</button>
                </a>
            </div>
        </div>
    );
};
