import React from 'react';

import {
    NexDoApplicationCard,
    NexDoApplicationDescription,
    NexDoApplicationImg,
    NexDoApplicationKnowMore,
    NexDoApplicationOverview,
} from '../components/ProductsDescription/Nexdo';

export const NexDoApplicationPage: React.FC = () => {
    return (
        <>
            <NexDoApplicationCard />
            <NexDoApplicationOverview />
            <NexDoApplicationImg />
            <NexDoApplicationDescription />
            <NexDoApplicationKnowMore />
        </>
    );
};
