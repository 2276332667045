import React from 'react';

import {
    EconnectApplicationCard,
    EconnectApplicationDescription,
    EconnectApplicationImg,
    EconnectApplicationKnowMore,
    EconnectApplicationOverview,
} from '../components/ProductsDescription/Econnect';

export const EconnectApplicationPage: React.FC = () => {
    return (
        <>
            <EconnectApplicationCard />
            <EconnectApplicationOverview />
            <EconnectApplicationImg />
            <EconnectApplicationDescription />
            <EconnectApplicationKnowMore />
        </>
    );
};
