import './styles.scss';
import 'react-phone-input-2/lib/style.css';

import { getList } from 'country-list-with-dial-code-and-flag';
import { httpsCallable } from 'firebase/functions';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { functions } from '../../Firebase';

interface ICountries {
    code: string;
    dial_code: string;
    flag: string;
    name: string;
}

export const Contacts: React.FC = () => {
    const initialDetails = { name: '', email: '', dial_code: '', phoneNumber: '', projectDetails: '' };
    const [details, setDetails] = useState(initialDetails);
    const [isClicked, setIsClicked] = useState(false);

    const [countries, setCountries] = useState<ICountries[]>([]);
    const [selectedCountry, setSelectedCountry] = useState(96);

    useEffect(() => {
        setCountries(getList());
    }, []);

    const handleChange = (indexValue: React.SetStateAction<number>) => {
        setSelectedCountry(indexValue);
        setDetails((prev) => ({ ...prev, phoneNumber: '' }));
    };
    const validateNumber = (targetNumber: EventTarget & HTMLInputElement) => {
        if (countries[selectedCountry].dial_code === '+91' && targetNumber.value.length !== 10) {
            targetNumber.setCustomValidity('enter a valid number');
            targetNumber.reportValidity();
        } else if (!isValidPhoneNumber(countries[selectedCountry].dial_code + targetNumber.value)) {
            targetNumber.setCustomValidity('enter a valid number');
            targetNumber.reportValidity();
        } else {
            targetNumber.setCustomValidity('');
            targetNumber.reportValidity();
        }

        setDetails((prev) => ({ ...prev, dial_code: countries[selectedCountry].dial_code, phoneNumber: targetNumber.value }));
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setIsClicked(true);

        try {
            const result = await httpsCallable<typeof details, { message: string }>(functions, 'contactForm')(details);

            if (result.data.message) {
                setDetails(initialDetails);
                toast.success(result.data.message);
            } else {
                throw new Error('Unknown error!');
            }
        } catch (error) {
            console.error('Failed to submit the form.', error);
            toast.error('Unable to submit request');
        }

        setIsClicked(false);
    };

    return (
        <div className='contacts full-vh' id='Careers'>
            <form className='form' onSubmit={handleSubmit}>
                <label htmlFor=''>Full Name</label>
                <input
                    type='text'
                    pattern='^[^ ][A-Za-z]+[?\sA-Za-z]+$'
                    value={details.name}
                    onChange={(e) => setDetails((prev) => ({ ...prev, name: e.target.value }))}
                    required
                />
                <label htmlFor=''>Your Email</label>
                <input
                    type='email'
                    pattern='^[^_$.][a-z0-9%+-]+[.]?[a-z0-9]+@[a-z0-9.-]+\.[a-z]{2,}$'
                    value={details.email}
                    onChange={(e) => setDetails((prev) => ({ ...prev, email: e.target.value }))}
                    required
                />
                <label htmlFor=''>Phone Number</label>
                <div className='select-container'>
                    <select className='country-name' onChange={(e) => handleChange(e.target.options.selectedIndex)}>
                        {countries?.map((element, index) => {
                            return (
                                <option key={index} value={index} selected={index === selectedCountry ? true : false}>
                                    {element.name}
                                </option>
                            );
                        })}
                    </select>
                    <select className='country-flag' onChange={(e) => handleChange(e.target.options.selectedIndex)}>
                        {countries?.map((element, index) => {
                            return (
                                <option key={index} value={index} selected={index === selectedCountry ? true : false}>
                                    {element.flag}
                                </option>
                            );
                        })}
                    </select>
                    <select className='country-code' onChange={(e) => handleChange(e.target.options.selectedIndex)}>
                        {countries?.map((element, index) => {
                            return (
                                <option key={index} value={index} selected={index === selectedCountry ? true : false}>
                                    {element.dial_code}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <input
                    pattern='^[0-9]*$'
                    className='country-number'
                    type='text'
                    value={details.phoneNumber}
                    onChange={(e) => validateNumber(e.target)}
                    required
                ></input>

                <label htmlFor=''>Your Message</label>
                <textarea
                    value={details.projectDetails}
                    onChange={(e) => setDetails((prev) => ({ ...prev, projectDetails: e.target.value }))}
                    required
                ></textarea>
                <button type='submit' disabled={isClicked}>
                    Get Started Now
                </button>
            </form>
            <div className='contacts'>
                <div className='header'>Synergize</div>
                <span>Reach us at:</span>

                <div>
                    <a href='mailto:info@entelegen.com'>info@entelegen.com</a>
                </div>
                <span>Call us at:</span>
                <div className='phonenumber'>
                    <a href='tel:+914223526421'>+914223526421</a>
                </div>
                <span>Address</span>
                <div className='address'>
                    <p>
                        300, GV Residency, Sowripalayam,
                        <br />
                        Coimbatore, Tamil Nadu, India - 641028
                    </p>
                </div>
            </div>
        </div>
    );
};
