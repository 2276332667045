import './styles.scss';

import React from 'react';

import nodeIcon from '../../../../assets/images/gymapplicationDescription/nodeIcon.svg';
import reactIcon from '../../../../assets/images/gymapplicationDescription/reactIcon.svg';
import { useTheme } from '../../../../utils';

export const EconnectApplicationDescription: React.FC = () => {
    const { theme } = useTheme();

    const img = [reactIcon, nodeIcon];
    const content = [
        {
            img: true,
            heading: 'Technology',
            para: "Embrackles developed eConnect, AWS cloud-based workforce management software that allows businesses to track their employees' daily login and productivity. The software uses biometric authentication to ensure accurate punch in and out times, eliminating the possibility of buddy punching or time theft. eConnect is designed to be multi-tenant, meaning it can support multiple businesses and customers across various platforms.The software is developed with React Native in the front-end and Node JS in the back-end, providing a user-friendly interface and seamless integration with existing systems. eConnect is also built on a SAAS model, allowing businesses to easily scale up or down their usage of the software as needed, without the need for additional hardware or software resources.",
        },
    ];
    return (
        <div className='econnect-description'>
            {content.map((item, index) => (
                <>
                    <div className='heading' key={index}>
                        {item.heading}
                    </div>
                    <div className='para' key={index}>
                        {item.img && (
                            <div className='img' style={theme === 'dark' ? { filter: 'invert(1)' } : {}}>
                                {img.map((item, index) => (
                                    <img src={item} alt='' key={index} />
                                ))}
                            </div>
                        )}
                        <div className='descrip'>{item.para}</div>
                    </div>
                </>
            ))}
        </div>
    );
};
