import './styles.scss';

import { Player } from '@lottiefiles/react-lottie-player';
import React, { useEffect, useRef, useState } from 'react';

import energyIcon from '../../assets/images/expertise/energyIcon.svg';
import insuIcon from '../../assets/images/expertise/insuranceIcon.svg';
import logIcon from '../../assets/images/expertise/logisticsIcon.svg';
import teleIcon from '../../assets/images/expertise/teleIcon.svg';
import energyAnimi from '../../assets/lottieAnimation/expertise/energyAnimi.json';
import insuranceAnimi from '../../assets/lottieAnimation/expertise/insuranceAnimi.json';
import logAnimi from '../../assets/lottieAnimation/expertise/logAnimi.json';
import teleAnimi from '../../assets/lottieAnimation/expertise/teleAnimi.json';

export const Expertise: React.FC = () => {
    const [active, setActive] = useState(0);
    const title = ['Insurance', 'Energy', 'Telecom', 'Logistics'];
    const slideColor = ['#6a4a97', '#01FF60', '#e68c3e', '#5baee0'];
    const menuImg = [insuIcon, energyIcon, teleIcon, logIcon];
    const content = [
        {
            start: 'We ',
            span: 'do this with transparency.',
            end: 'We bring forth 5+ years of domain experience with insurance analytics as forerunner. Our specialties include customer analytics, fraud detection, and market share analytics. We provide both predictive and prescriptive analytics solutions.',
            img: insuranceAnimi,
        },
        {
            start: 'We use ',
            span: 'environmental-friendly alternatives.',
            end: 'With over 3+ years of experience in the energy industry, we specialize in developing innovative solutions to help our clients optimize energy consumption, reduce costs, and improve operational efficiency. Our solutions are designed to reduce consumption by implementing predictive analytics solutions that use data analysis and machine learning algorithms to provide insights into energy consumption patterns. By leveraging these insights, our clients can make informed decisions and reduce energy waste.',
            img: energyAnimi,
        },
        {
            start: 'We have ',
            span: 'good terminals to access the network',
            end: 'We have 3+ years of expertise in the telecom industry, we offer a range of solutions tailored to meet the needs of this dynamic field. Our expertise lies in the areas of network optimization, software-defined networking, and the internet of things (IoT). We work closely with our clients to understand their unique telecom requirements and develop customized solutions that meet their specific needs. Our team is dedicated to delivering high-quality solutions that are reliable, scalable, and sustainable.',
            img: teleAnimi,
        },
        {
            start: 'We ',
            span: 'manage effective flow of services',
            end: 'We have 3+ years of industry experience in developing supply chain management tools and predicting material flow for services, and all related information between the point of origin and the point of consumption to meet customer requirements.',
            img: logAnimi,
        },
    ];
    const markerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        markerRef.current &&
            ((markerRef.current.style.left = 4 + active * 25 + '%'),
            (markerRef.current.style.width = 15 + '%'),
            (markerRef.current.style.borderBottomColor = slideColor[active]));
    }, [active]);

    return (
        <div className='expertise full-vh' id='Expertise'>
            <div className='header'>Expertise</div>
            <div className='top-menu'>
                {menuImg.map((item, index) => (
                    <div
                        onClick={() => {
                            setActive(index);
                        }}
                        key={index}
                    >
                        <img src={item} alt='' />
                        <span>{title[index]}</span>
                    </div>
                ))}
                <div className='marker' ref={markerRef}></div>
            </div>
            {content.map(
                (item, index) =>
                    index === active && (
                        <div className='grid-wrapper' key={index}>
                            <div className='para'>
                                <div>
                                    <div>
                                        <h2>{title[index]}</h2>
                                    </div>
                                    <div>{item.end}</div>
                                </div>
                            </div>
                            <div className='expertise-animi'>
                                <Player src={item.img} autoplay loop />
                            </div>
                        </div>
                    )
            )}
        </div>
    );
};
