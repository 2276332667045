import './styles.scss';

import React from 'react';

export const EconnectApplicationOverview: React.FC = () => {
    const overview = [
        {
            heading: 'Overview',
            para: 'Entelegen has created a revolutionary product, HRM tool that is capable of tracking workforce day to day logins through biometric and cross platform integration.',
        },
        {
            heading: 'Problem Faced',
            para: 'As businesses grow, managing and tracking productivity for their business and clients business  becomes increasingly challenging. Traditional methods such as manual punch in and out systems and paper timesheets can be inaccurate and time-consuming, resulting in wasted resources and reduced efficiency. A solution was needed to streamline the workforce management process and improve overall productivity.',
        },
        {
            heading: 'Solution',
            para: 'With eConnect, businesses can streamline their workforce management process, resulting in improved productivity and reduced costs. The software provides centralized data that can be easily analyzed to make informed decisions about employee performance and resource allocation.',
        },
    ];
    return (
        <div className='econnectapplication-overview'>
            {overview.map((item, index) => (
                <div className='grid-wrapper' key={index}>
                    <div className='heading'>{item.heading}</div>
                    <p className='para'>{item.para}</p>
                </div>
            ))}
        </div>
    );
};
