import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Career, JobDescription, PageContainer } from '../components';
import { EconnectApplicationPage } from './EconnectApplicationPage';
import { GymApplicationPage } from './GymApplicationPage';
import { InsuranceAnalyticsPage } from './InsuranceAnalyticsPage';
import { LandingPage } from './LandingPage';
import { NexDoApplicationPage } from './NexdoApplicationPage';
import { PsgApplicationPage } from './PsgApplicationPage';

export const Pages: React.FC = () => {
    return (
        <Routes>
            <Route element={<PageContainer />}>
                <Route path='/product/gymapplication' element={<GymApplicationPage />} />
                <Route path='/product/nexdoapplication' element={<NexDoApplicationPage />} />
                <Route path='/product/econnectapplication' element={<EconnectApplicationPage />} />
                <Route path='/product/psgapplication' element={<PsgApplicationPage />} />
                <Route path='/product/insuranceanalytics' element={<InsuranceAnalyticsPage />} />
                <Route path='/career' element={<Career />} />
                <Route path='/job-description/:jobDataIndex/:jobIndex' element={<JobDescription />} />
                <Route path='/' element={<LandingPage />} />
            </Route>
        </Routes>
    );
};
