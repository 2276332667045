import './styles.scss';

import React from 'react';

import nodeIcon from '../../../../assets/images/gymapplicationDescription/nodeIcon.svg';
import reactIcon from '../../../../assets/images/gymapplicationDescription/reactIcon.svg';
import { useTheme } from '../../../../utils';

export const PsgApplicationDescription: React.FC = () => {
    const { theme } = useTheme();

    const img = [reactIcon, nodeIcon];
    const content = [
        {
            img: true,
            heading: 'Technology',
            para: 'Our team created interactive and engaging platforms using full-stack React and Node.js web applications. These platforms feature brain exercises designed to effectively stimulate the brains of individuals across different age groups. These exercises include Guess the Word, Jumble Word, Directional Aspects, Picture Naming, Reading Comprehension, Rhyming Word with audio, Identifying Rhyming Word through Images, and Missing Images. We collect data across various screening texts, which serves as a crucial metric and provides valuable feedback to users.',
        },
    ];
    return (
        <div className='psg-description'>
            {content.map((item, index) => (
                <>
                    <div className='heading' key={index}>
                        {item.heading}
                    </div>
                    <div className='para' key={index}>
                        {item.img && (
                            <div className='img' style={theme === 'dark' ? { filter: 'invert(1)' } : {}}>
                                {img.map((item, index) => (
                                    <img src={item} alt='' key={index} />
                                ))}
                            </div>
                        )}
                        <div className='descrip'>{item.para}</div>
                    </div>
                </>
            ))}
        </div>
    );
};
