import './style.scss';

import React from 'react';

import office from '../../assets/images/career/office.jpg';
import { BlueButton } from '../Button';

export const CareerCard: React.FC = () => {
    return (
        <div className='career full-vh' id='Career'>
            <div className='header'>Careers</div>
            <div className='grid-wrapper'>
                <div className='img'>
                    <img src={office} alt='' />
                </div>
                <div className='content'>
                    <div className='career-content'>Lets Work Together</div>
                    <div className='about'>
                        <p>
                            Onboard Entelegen if you are passionate about making a difference in the virtual world. By supporting and
                            enhancing the way technology interacts with business, we enrich both consumer and business experience.
                        </p>
                    </div>
                    <BlueButton link='/career#top' text='Get started now' />
                </div>
            </div>
        </div>
    );
};
