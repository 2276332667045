import './styles.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';

import { getList } from 'country-list-with-dial-code-and-flag';
import { httpsCallable } from 'firebase/functions';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import empTypeIcon from '../../assets/images/careerPage/empTypeIcon.svg';
import arrowIcon from '../../assets/images/jobDescriptionPage/careers_arrow.svg';
import experienceIcon from '../../assets/images/jobDescriptionPage/experience.svg';
import locationIcon from '../../assets/images/jobDescriptionPage/location.svg';
import { functions } from '../../Firebase';
import jobData from '../../jobData.json';

interface ICountries {
    code: string;
    dial_code: string;
    flag: string;
    name: string;
}

export const JobDescription: React.FC = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { jobDataIndex, jobIndex } = useParams();
    const [btnTxt, setBtnTxt] = useState('Submit Application');
    const [countries, setCountries] = useState<ICountries[]>([]);
    const [selectedCountry, setSelectedCountry] = useState(96);

    useEffect(() => {
        setCountries(getList());
    }, []);

    const handleChange = (indexValue: React.SetStateAction<number>) => {
        setSelectedCountry(indexValue);
        setDetails((prev) => ({ ...prev, mobile: '' }));
    };
    const validateNumber = (targetNumber: EventTarget & HTMLInputElement) => {
        if (countries[selectedCountry].dial_code === '+91' && targetNumber.value.length !== 10) {
            targetNumber.setCustomValidity('enter a valid number');
            targetNumber.reportValidity();
        } else if (!isValidPhoneNumber(countries[selectedCountry].dial_code + targetNumber.value)) {
            targetNumber.setCustomValidity('enter a valid number');
            targetNumber.reportValidity();
        } else {
            targetNumber.setCustomValidity('');
            targetNumber.reportValidity();
        }

        setDetails((prev) => ({ ...prev, dial_code: countries[selectedCountry].dial_code, mobile: targetNumber.value }));
    };

    const jdData = jobData[Number(jobDataIndex)]?.jobs[Number(jobIndex)];

    const bottomRef = useRef<HTMLDivElement>({} as HTMLDivElement);
    const topRef = useRef<HTMLDivElement>({} as HTMLDivElement);

    const initialData = {
        firstname: '',
        lastname: '',
        email: '',
        dial_code: '',
        mobile: '',
        resume: {} as File,
        role: jdData.role || '',
        projectlink: '',
    };

    useEffect(() => {
        topRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);

    useEffect(() => {
        setBtnTxt(isSubmitting ? 'Submitting...' : 'Submit Application');
    }, [isSubmitting]);

    const [details, setDetails] = useState(initialData);

    const handleApply = () => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        setIsSubmitting(true);

        const resume: File = details.resume;

        const data = {
            ...details,
            resume: {
                data: Buffer.from(await resume.arrayBuffer()).toJSON(),
                name: resume.name,
                type: resume.type,
            },
        };

        try {
            const result = await httpsCallable<typeof data, { message: string }>(functions, 'registerForm')(data);

            if (result.data.message) {
                setDetails(initialData);
                toast.success(result.data.message);
            } else {
                throw new Error('Unknown error!');
            }
        } catch (error) {
            debugger;
            console.error('Failed to submit the application', error);
            toast.error('Unable to submit the application');
        }

        setIsSubmitting(false);
    };

    return (
        <div className='job-description full-vh' ref={topRef}>
            <div className='jd-banner'>
                <div className='banner'>
                    <div className='arrow-icon'>
                        <img src={arrowIcon} alt='back-button' onClick={() => history.back()} />
                        <h3 onClick={() => history.back()}>Careers</h3>
                    </div>
                    <h1>{jdData.role}</h1>
                    <div className='employment-details'>
                        <div className='employment-type'>
                            <img src={empTypeIcon} alt='' />
                            {jdData.empType}
                        </div>
                        <div className='experience'>
                            <img src={experienceIcon} alt='' />
                            {jdData.experience} years Experience
                        </div>
                        <div className='job-city'>
                            <img src={locationIcon} alt='' />
                            {jdData.city}
                        </div>
                    </div>
                </div>

                <button className='apply-btn' onClick={() => handleApply()}>
                    Apply Now
                </button>
            </div>
            <div className='description'>{jdData.description}</div>
            <div className='responsibilities'>
                <h2>Responsibilities</h2>
                <ul>
                    {jdData.responsibilities.map((responsibility, responsibilityIndex) => (
                        <li key={`responsibility-${responsibilityIndex}`}>{responsibility}</li>
                    ))}
                </ul>
            </div>
            {jdData.qualifications.length > 0 ? (
                <div className='qualifications'>
                    <h2>Qualifications</h2>
                    <ul>
                        {jdData.qualifications.map((qualification, qualificationIndex) => (
                            <li key={`qualification-${qualificationIndex}`}>{qualification}</li>
                        ))}
                    </ul>
                </div>
            ) : null}

            <form onSubmit={handleSubmit}>
                <h2 className='form-heading'>Submit Your Application</h2>
                <div className='name-fields' ref={bottomRef}>
                    <div>
                        <label htmlFor=''>First name*</label>
                        <input
                            type='text'
                            placeholder='First Name'
                            value={details.firstname}
                            pattern='^[A-Za-z]+[?\sA-Za-z]+$'
                            onChange={(e) => setDetails((prev) => ({ ...prev, firstname: e.target.value }))}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor=''>Last name*</label>
                        <input
                            type='text'
                            placeholder='Last Name'
                            value={details.lastname}
                            pattern='^[A-Za-z?\s]+$'
                            onChange={(e) => setDetails((prev) => ({ ...prev, lastname: e.target.value }))}
                            required
                        />
                    </div>
                </div>
                <label htmlFor=''>Email*</label>
                <input
                    type='email'
                    placeholder='Email'
                    value={details.email}
                    pattern='^[^_$.][a-z0-9%+-]+[.]?[a-z0-9]+@[a-z0-9.-]+\.[a-z]{2,}$'
                    onChange={(e) => setDetails((prev) => ({ ...prev, email: e.target.value }))}
                    required
                />
                <label htmlFor=''>Mobile number *</label>
                <div className='select-container'>
                    <select className='country-name' onChange={(e) => handleChange(e.target.options.selectedIndex)}>
                        {countries?.map((element, index) => {
                            return (
                                <option key={index} value={index} selected={index === selectedCountry ? true : false}>
                                    {element.name}
                                </option>
                            );
                        })}
                    </select>
                    <select className='country-flag' onChange={(e) => handleChange(e.target.options.selectedIndex)}>
                        {countries?.map((element, index) => {
                            return (
                                <option key={index} value={index} selected={index === selectedCountry ? true : false}>
                                    {element.flag}
                                </option>
                            );
                        })}
                    </select>
                    <select className='country-code' onChange={(e) => handleChange(e.target.options.selectedIndex)}>
                        {countries?.map((element, index) => {
                            return (
                                <option key={index} value={index} selected={index === selectedCountry ? true : false}>
                                    {element.dial_code}
                                </option>
                            );
                        })}
                    </select>
                    <input
                        pattern='^[0-9]*$'
                        className='country-number'
                        type='text'
                        value={details.mobile}
                        onChange={(e) => validateNumber(e.target)}
                        required
                    ></input>
                </div>

                <label htmlFor=''>Resume*</label>
                <input
                    title=' '
                    type='file'
                    accept='.pdf'
                    placeholder='Resume'
                    onChange={(e) => setDetails((prev) => ({ ...prev, resume: e.target.files?.[0] as File }))}
                    autoComplete='off'
                    required
                />
                <sub>* Upload pdf files only</sub>
                <div className='work-links'>
                    <h3 className='work-links-heading'>Social Network and Web Links</h3>
                    <label htmlFor=''>Provide us with your personal links if any (Git/ Dribble/ Behance/ Pinterest/ Blog/ Medium)</label>
                    <input
                        type='text'
                        placeholder='Hyper Link'
                        value={details.projectlink}
                        onChange={(e) => setDetails((prev) => ({ ...prev, projectlink: e.target.value }))}
                    />
                </div>

                <button className='submit-btn' type='submit' disabled={isSubmitting}>
                    {btnTxt}
                </button>
            </form>
        </div>
    );
};
