import './styles.scss';

import React from 'react';

import nodeIcon from '../../../../assets/images/gymapplicationDescription/nodeIcon.svg';
import reactIcon from '../../../../assets/images/gymapplicationDescription/reactIcon.svg';
import { useTheme } from '../../../../utils';

export const InsuranceAnalyticsDescription: React.FC = () => {
    const { theme } = useTheme();

    const img = [reactIcon, nodeIcon];
    const content = [
        {
            img: true,
            heading: 'Technology',
            para: 'To solve this challenge,We used Cloud services and AI to create a pipeline to process these data sets. This allowed us to ingest and process the data from the different sources and formats in a unified manner. We also used complicated scripts to enable real-time data streaming, which allowed us to continuously update our data warehouse with the latest data from both sources. Next, we developed a data model that can effectively store and process the data. We used a combination of Azure for data storage and processing. This enabled us to store large amounts of data and retrieve it efficiently, which is critical for generating reports and studies. Finally, we used a combination of data processing tools and data visualization tools, this allowed us to generate reports and studies that provided valuable insights on customer trends and performance for both the insurance and e-commerce industries.',
        },
    ];
    return (
        <div className='insurance-description'>
            {content.map((item, index) => (
                <>
                    <div className='heading' key={index}>
                        {item.heading}
                    </div>
                    <div className='para' key={index}>
                        {/* {item.img && (
                            <div className='img' style={theme === 'dark' ? { filter: 'invert(1)' } : {}}>
                                {img.map((item, index) => (
                                    <img src={item} alt='' key={index} />
                                ))}
                            </div>
                        )} */}
                        <div className='descrip'>{item.para}</div>
                    </div>
                </>
            ))}
        </div>
    );
};
