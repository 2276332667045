import React from 'react';

import {
    PsgApplicationCard,
    PsgApplicationDescription,
    PsgApplicationImg,
    PsgApplicationKnowMore,
    PsgApplicationOverview,
} from '../components/ProductsDescription/Psgapplication';

export const PsgApplicationPage: React.FC = () => {
    return (
        <>
            <PsgApplicationCard />
            <PsgApplicationOverview />
            <PsgApplicationImg />
            <PsgApplicationDescription />
            <PsgApplicationKnowMore />
        </>
    );
};
