import './styles.scss';

import { Player } from '@lottiefiles/react-lottie-player';
import React, { useEffect, useRef, useState } from 'react';

import brainIcon from '../../assets/images/services/aiml2.svg';
import andIcon from '../../assets/images/services/andIcon.svg';
import apacheSparkIcon from '../../assets/images/services/apachesparkIcon.svg';
import appleIcon from '../../assets/images/services/appleIcon.svg';
import awsIcon from '../../assets/images/services/awsIcon.svg';
import cloudIcon from '../../assets/images/services/cloudIcon.svg';
import dockerIcon from '../../assets/images/services/dockerIcon.svg';
import enterpriseIcon from '../../assets/images/services/enterpriseIcon.svg';
import googleCloudIcon from '../../assets/images/services/googlecloudIcon.svg';
import ibmCloudIcon from '../../assets/images/services/ibmCloudIcon.svg';
import ionicIcon from '../../assets/images/services/ionicframework.svg';
import javaIcon from '../../assets/images/services/javaIcon.svg';
import jenkinsIcon from '../../assets/images/services/jenkinsIcon.svg';
import jiraIcon from '../../assets/images/services/jiraIcon.svg';
import nodeIcon from '../../assets/images/services/jsIcon.svg';
import mobIcon from '../../assets/images/services/mainIcon.svg';
import mongodbIcon from '../../assets/images/services/mongodbIcon.svg';
import mysqlIcon from '../../assets/images/services/mysqlIcon.svg';
import dotnetIcon from '../../assets/images/services/netframeworkIcon.svg';
import python from '../../assets/images/services/pythonIcon.svg';
import pytorch from '../../assets/images/services/pytorchIcon.svg';
import qaulityIcon from '../../assets/images/services/qaulityIcon.svg';
import reactIcon from '../../assets/images/services/reactIcon.svg';
import seleniumIcon from '../../assets/images/services/seleniumIcon.svg';
import snowflakeIcon from '../../assets/images/services/snowflakeIcon.svg';
import solutionIcon from '../../assets/images/services/solutionIcon.svg';
import tensorFlow from '../../assets/images/services/tensorflowIcon.svg';
import tensorIcon from '../../assets/images/services/tensorIcon.svg';
import uipathIcon from '../../assets/images/services/uipathIcon.svg';
import upArrow from '../../assets/lottieAnimation/services/100939.json';

export const Services: React.FC = () => {
    const [active, setActive] = useState(0);
    const [softwarecontent, setSoftwareContent] = useState('');

    const menuItem = [
        'Application',
        // 'Product Development',
        'AI & ML Solutions',
        'Enterprise Middleware',
        'Cloud Transformation',
        'Quality Assurance',
        'Data Solutions',
    ];
    const menuIcon = [mobIcon, brainIcon, enterpriseIcon, cloudIcon, qaulityIcon, solutionIcon];
    const content = [
        {
            p1: 'As a professional services firm, we are committed to delivering custom-built integrated mobile applications with our lean team - agile delivery. We provide competitive delivery timelines and costing with our pre-built, deployment-ready components across React JS, Redux, TypeScript, Next.js, and React Native technology stacks. We work upon custom prototypes with wireframe creation and emphasize on pixel-perfect implementation between devices in the development and testing phase.',
            overview: { animi: upArrow, per: '30%', p: 'Faster ', span: 'testing process' },
            p2: " We have 5+ years of experience in mobile application development. It's based on a modified version of theLinux kernel, designed primarily for smartphones and tablets.",
        },
        // {
        //     p1: 'As a professional services firm, we are committed to delivering custom-built integrated mobile applications with our lean team - agile delivery. We provide competitive delivery timelines and costing with our pre-built, deployment-ready components across React JS, Redux, TypeScript, Next.js, and React Native technology stacks. We work upon custom prototypes with wireframe creation and emphasize on pixel-perfect implementation between devices in the development and testing phase.',
        //     overview: { animi: upArrow, per: '30%', p: 'Faster ', span: 'testing process' },
        //     p2: " We have 5+ years of experience in mobile application development. It's based on a modified version of theLinux kernel, designed primarily for smartphones and tablets.",
        // },
        {
            p1: 'We build customized data science solutions with AI and ML models, tailored to specific business use cases and specific industry scenarios for our customers. We also deliver these solutions into a unified analytics platform to simplify business decisions and reporting. \nIn the field of AI Research & Application of Machine Learning models, we have an experienced & passionate team comprising of bachelors, masters, and doctorates from across the globe. ',
            overview: { animi: upArrow, per: '10+', p: 'Years of', span: 'experience in AI' },
            p2: 'High-level, class-based, object-oriented programming language that is designed to have as few implementation dependencies as possible.',
        },
        {
            p1: 'We design vibrant UI/UX for rich customer experience and integrated solutions for business.\nWe have extensive experience in building mobile platforms, web frameworks, CRM, workflow engines, and scalable middleware including REST APIs, message queues, micro-services, data access layers etc,.',
            overview: { per: '40%', p: 'Faster', span: 'Middleware Integrations' },
            p2: 'Provide Cloud computing platforms and APIs to individuals, companies, and governments, on a metered pay-as-you-go basis. Provide a variety of basic abstract technical infrastructure and distributed computing building blocks and tools.',
        },
        {
            p1: 'Building & Deployment of DevOps cloud infrastructure - AWS and Google Cloud Platform.\nCloud migration expertise in both, hybrid and multi-cloud architectures along with PaaS automations and CI/CD pipelines.',
            overview: { per: '100%', p: 'Secure', span: 'Deployments' },
            p2: 'We have 3+ years of experience in the portable framework for testing web applications it provides a playback tool for authoring functional tests and also provides to write tests in a number of popular programming languages including C#, Groovy, Java, Perl, PHP, Python, Ruby, and Scala.',
        },
        {
            p1: 'We have strong manual and automation capabilities for QA process with comprehensive knowledge of test management tools such as test link, test rail, JIRA etc,.\nWe have proven track record in establishing QA pipelines by leveraging CI/CD pipelines setup on Jenkins.',
            overview: { animi: upArrow, per: 'Top', p: 'Preference  ', span: 'for Quality' },
            p2: 'A Relational database management system programmers use to create, modify and extract data from the relational database, as it organizes data into one or more data tables in which data types are related to each other and these relations help structure the data.',
        },
        {
            p1: 'We work on both traditional RDBMS databases like Oracle, SQL Server, MySQL, and modern NoSQL databases like MongoDB, MySQL, influx DB and the likes.\nExperience in designing and solutioning polyglot architectures.\nExpertise in data migrations and data modernization strategies.',
            overview: { animi: upArrow, per: '70%', p: 'Efficient  ', span: 'Data Modelling' },
            p2: 'A Relational database management system programmers use to create, modify and extract data from the relational database, as it organizes data into one or more data tables in which data types are related to each other and these relations help structure the data.',
        },
    ];

    const softwareIcon = [
        [andIcon, appleIcon, reactIcon, tensorIcon, ionicIcon],
        // [andIcon, appleIcon, reactIcon, tensorIcon, ionicIcon],
        [tensorFlow, pytorch, python],
        [javaIcon, nodeIcon, dotnetIcon, dockerIcon],
        [awsIcon, googleCloudIcon, ibmCloudIcon],
        [seleniumIcon, jiraIcon, jenkinsIcon, uipathIcon],
        [mysqlIcon, mongodbIcon, snowflakeIcon, apacheSparkIcon],
    ];

    const softwareIconContent = [
        [
            {
                p2: "Entelegen has 5+ years experience in mobile application development. It's based on modified version of the Linux kernel, designed primarily for smartphones and tablets.",
            },
            {
                p2: 'Entelegen has 10+ years experience in APPLE platform. We create exclusive mobile applications as per client needs. We create, develop, deploy, update, and maintain  across all APPLE platforms including the iPhone, iPad, Apple Watch, and all the latest APPLE gadgets.',
            },
            {
                p2: 'Entelegen has 10+ years of strong experience in JavaScript frameworks. We provide expert skills in React & React Native frameworks, that enables our developers to leverage native platform capabilities with a common development framework.',
            },
            {
                p2: "Entelegen has 2+ years experience in Flutter framework. It's a vibrant UI software development kit that enables us to develop cross-platform applications for Android, iOS, Linux, Mac, Windows, Google Fuchsia, and the web using single codebase.",
            },
            {
                p2: 'Entelegen has 3+ years experience in Ionic framework creating hybrid mobile applications to integrate with other libraries such as Angular, Cordova, etc.',
            },
        ],
        [
            {
                p2: "Entelegen has  3+ years experience in building TensorFlow based custom libraries for machine learning that are leveraged across a wide range of it's symbolic math libraries based on dataflow and differentiable programming.",
            },
            {
                p2: 'Entelegen has 3+ years experience in applying PyTorch frameworks to our customized computer vision and natural language processing models.',
            },
            {
                p2: 'Entelegen has over a decade of niche experience in developing Python-based software for both on-prem and cloud deployments. Python is an interpreted high-level general-purpose programming language with a design philosophy that emphasizes code readability with its notable use of significant indentation.',
            },
        ],
        [
            {
                p2: 'High-level, class-based, object-oriented programming language that is designed to minimize implementation dependencies.',
            },
            {
                p2: 'Cross-platform, back-end JavaScript runtime environment that runs on the V8 engine that exclusively executes JavaScript code outside a web browser.',
            },
            {
                p2: '.NET framework encompasses class library FCL and provides language interoperability across several programming languages. Programs written for .NET Framework execute in a CLR.',
            },
            {
                p2: 'Docker is a PaaS product that uses OS-level virtualization to deliver software packages called containers. It can effectively package an application and its dependencies in a virtual container that can run on any Linux, Windows, macOS computer etc,.',
            },
        ],
        [
            {
                p2: 'Provide cloud computing platforms and APIs to individuals, companies, and governments, on a metered pay-as-you-go basis. Provide a variety of basic abstract technical infrastructure and distributed computing building blocks and tools.',
            },
            {
                p2: 'Suite of cloud computing services that runs on the same infrastructure that Google uses internally for its end-user products, It provides infrastructure as a service, platform as a service, and serverless computing environments.',
            },
            {
                p2: 'IBM Cloud is a set of cloud computing services for business offered by the information technology company IBM. It combines platform as a service with infrastructure as a service.',
            },
        ],
        [
            {
                p2: 'Entelegen has 3+ years experience in the portable framework for testing web applications. It provides a playback tool for authoring functional tests and also provides to write tests in a number of popular programming languages including C#, Groovy, Java, Perl, PHP, Python, Ruby, and Scala.',
            },
            {
                p2: 'We use Jira for issue tracking and as a project management tool, which is intended as generic base software, including agile methodological features with scrum framework.',
            },
            {
                p2: 'Entelegen has 2+ years experience working in Jenkins automation server which helps us to automate the parts of software development related to building, testing, deploying, and facilitating continuous integration and continuous delivery.',
            },
            {
                p2: 'Entelegen has 2+ years experience using Ui path to prebuilt automation components which can be combined to automate routine processes.',
            },
        ],
        [
            {
                p2: 'A Relational database management system programmers use to create, modify and extract data from the relational database, as it organizes data into one or more data tables in which data types are related to each other and these relations help structure the data.',
            },
            {
                p2: 'Source-available cross-platform document-oriented database program. Classified as a NoSQL database, it uses JSON-like documents with optional schemas. It was developed by MongoDB Inc. and licensed under the Server-Side Public License.',
            },
            {
                p2: 'Cloud-based data storage and analytics service, generally termed "data warehouse-as-a-service". It allows corporate users to store and analyse data using cloud-based hardware and software.',
            },
            {
                p2: 'Unified analytics engine for large-scale data processing. Spark provides an interface for programming entire clusters with implicit data parallelism and fault tolerance.',
            },
        ],
    ];
    const activeRef = useRef<HTMLDivElement>(null);
    const markerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (markerRef.current) {
            markerRef.current.style.top = `calc(100% / 6 * ${active})`;
            markerRef.current.style.height = `calc(100% / 6 )`;
        }
    }, [active]);
    return (
        <div className='services full-vh' id='Services'>
            <div className='header'>Services & Solutions</div>
            <div className='service-container'>
                <div className='side-menu'>
                    <div
                        className={`active ${active === 0 ? 'first-element' : active === menuItem.length - 1 ? 'last-element' : ''}`}
                        ref={markerRef}
                    ></div>
                    {menuItem.map((item, index) => (
                        <div
                            ref={index === active ? activeRef : null}
                            onClick={() => {
                                setActive(index);
                                setSoftwareContent('');
                            }}
                            className={`${index === active ? 'menu-active' : ''} ${
                                index === 0 ? 'menu first-element' : index === menuItem.length - 1 ? 'menu last-element' : 'menu'
                            }`}
                            key={index}
                        >
                            <span className='span-content' id='span-content'>
                                {item}
                            </span>
                            <img src={menuIcon[index]} alt='' />
                        </div>
                    ))}
                </div>
                <div className='content'>
                    {content.map((item, index) => (
                        <div className={`${index === active ? 'menu-content active' : 'menu-content'}`} key={index}>
                            <img className='img' src={menuIcon[index]} alt='' />
                            <h2 className='menu-heading'>{menuItem[index]}</h2>
                            <div>
                                <h2>Overview</h2>
                                <p>{item.p1}</p>
                                <div className='blue-content'>
                                    {item.overview.animi && <Player className='up-arrow' src={item.overview.animi} autoplay loop />}
                                    <div className='per'>{item.overview.per}</div>
                                    <div className='per-content'>
                                        <div>{item.overview.p}</div>
                                        <span>{item.overview.span}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2>Software & TechStack</h2>

                                <div className='icons'>
                                    {softwareIcon[index].map((item, index) => (
                                        <div key={index} className='container'>
                                            <img
                                                className={`img ${Number(softwarecontent) === index ? 'active' : null}`}
                                                src={item}
                                                alt=''
                                                key={index}
                                                onClick={() => {
                                                    setSoftwareContent(index.toString()), console.log(index);
                                                }}
                                            />
                                            <div
                                                className={`${Number(softwarecontent) === index ? 'bottom-ref-active' : 'bottom-ref'}`}
                                            ></div>
                                        </div>
                                    ))}
                                </div>

                                <p>{softwareIconContent[index][Number(softwarecontent)]?.p2}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
