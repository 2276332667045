import './styles.scss';

import React from 'react';

import arrowIcon from '../../../../assets/images/jobDescriptionPage/careers_arrow.svg';
import psgimg from '../../../../assets/images/product/psg_dashboard.png';
import { useTheme } from '../../../../utils';

export const PsgApplicationCard: React.FC = () => {
    const { theme } = useTheme();
    return (
        <div className='psgapplication-card vh' id='top'>
            <div className='arrow-icon' style={{ filter: theme === 'light' ? 'brightness(0)' : 'none' }}>
                <img src={arrowIcon} alt='back-button' onClick={() => history.back()} />
                <h3 style={{ color: 'white' }} onClick={() => history.back()}>
                    Back to home
                </h3>
            </div>
            <div className='grid-wrapper'>
                <div className='descrip'>
                    <p>Little Champions</p>
                    <img src={psgimg} alt='' />
                </div>
            </div>
        </div>
    );
};
